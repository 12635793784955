<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Prebid by pubstack options</p>
    </div>
    <v-card class="mb-6 pa-2">
      <v-card-subtitle> </v-card-subtitle>
      <v-card-text>
        Attention: Une modification ici n'impactera les imports qu'à partir du
        lendemain.
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col
            cols="3"
            v-for="(item, index) in list"
            :key="item.id"
            :value="item.var_name"
          >
            <v-card class="ma-2 pa-2">
              <v-card-text>
                <v-switch
                  @change="saveMeta(index)"
                  v-model="item.var_value_int"
                  :label="item.var_name"
                  hide-details
                  color="primary"
                  :loading="item.loading"
                ></v-switch>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";

export default {
  name: "GamPrebidPubstack",
  async created() {},
  data() {
    return {
      departmentId: 2,
      varGroup: "gam_prebid_pubstack_key_value_list",
      list: [],
    };
  },
  methods: {
    async saveMeta(index) {
      let item = this.list[index];
      console.log(`item`, item);
      let new_var_value_int = item.var_value_int ? 1 : 0;
      const params = {
        department_id: this.departmentId,
        var_group: this.varGroup,
        var_name: item.var_name,
        var_value_int: new_var_value_int,
      };
      console.log("save params", params);
      item.loading = true;
      const response = await axios.put("/metas/save", params);
      item.loading = false;
      if (response.status === 200) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Le CA de "${item.var_name}" ${
            item.var_value_int ? "sera désormais" : "ne sera désormais plus"
          } pris en compte.`,
          color: "success",
          timeout: 3000,
        });
      } else {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Une erreur est survenue à l'enregistrement de la prise en compte ou non du CA "${item.var_name}".`,
          color: "error",
          timeout: 3000,
        });
      }
      this.getMetas();
    },
    async getMetas() {
      const queryParams = {
        department_id: this.departmentId,
        var_group: this.varGroup,
      };
      const {
        data: { items: responseItems },
      } = await axios.get("/metas/list-by-group-site", {
        params: queryParams,
      });
      for (let i in responseItems) {
        responseItems[i]["loading"] = false;
      }
      this.list = responseItems;
    },
  },
  mounted() {
    this.getMetas();
  },
};
</script>
